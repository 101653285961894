import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    // LIMIT_DEFAULT_TEXT_LENGTH,
    // LIMIT_DESCRIPTION_LENGTH,
    // LIMIT_HEADLINE_LENGTH,
    // LIMIT_NAME_LENGTH,
    EXTERNAL_LINK_SOURCE_PATERN,
    INTERNAL_LINK_SOURCE_PATERN,
} from '../../../common/constants';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FlexGrid } from '../../../components/FlexGrid';
// import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Select } from '../../../components/Select';
import { TargetLink } from '../../../components/TargetLink';
import { Text } from '../../../components/Text';
import { TextEditor } from '../../../components/TextEditor';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { InAppNotificationType } from '../../../types';
import {
    isNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';
import { notificationIcons } from '../../Videos/components/SendNotification';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    inAppNotificationState: [
        InAppNotificationType,
        React.Dispatch<React.SetStateAction<InAppNotificationType>>
    ];
}

export const InAppNotificationContent: React.FC<Props> = ({
    stepNavigator,
    inAppNotificationState,
}) => {
    const dispatch = useDispatch();
    const [inAppNotification, setInAppNotification] = inAppNotificationState;
    const { content } = inAppNotification;
    const { appLanguages } = inAppNotification.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    useEffect(() => {
        const newInAppNotification = { ...inAppNotification };
        newInAppNotification.identifierDefinition.appLanguages.forEach(
            (language) => {
                // if (!newInAppNotification.content.headline[language]) {
                //     newInAppNotification.content.headline[language] = '';
                // }
                if (!newInAppNotification.content.bodyText[language]) {
                    newInAppNotification.content.bodyText[language] = '';
                }
                if (!newInAppNotification.content.targetLink[language]) {
                    newInAppNotification.content.targetLink[language] = '';
                }
            }
        );
        setInAppNotification(newInAppNotification);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(content.headline[language] && content.bodyText[language])
            language === activeLanguage
    );

    const dataIncomplete =
        !content.icon ||
        // isNotRequireAndWhiteSpaces(content.headline) ||
        isNotRequireAndWhiteSpaces(content.bodyText);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <Form>
                <Select
                    form
                    label="icon"
                    placeholder="- Select Icon -"
                    info={{
                        title: 'In-App Notification icon',
                        content:
                            'Selected In-App Notification icon will display in Mobile Notifications',
                    }}
                    options={[
                        'Alert',
                        'Comment',
                        'Reward',
                        'New Release',
                        'New Video',
                        'New Article',
                        'New Quiz',
                        'Points Allocation',
                        'Learning Card Release',
                    ]}
                    value={content.icon}
                    onChange={(val: string) =>
                        setInAppNotification({
                            ...inAppNotification,
                            content: {
                                ...inAppNotification.content,
                                icon: val,
                            },
                        })
                    }
                    renderValue={(value: string) => (
                        <FlexGrid width="100%" alignItems="center" gap="0.5em">
                            {notificationIcons[value]}
                            <Text>{value}</Text>
                        </FlexGrid>
                    )}
                    renderOption={(option: string) => (
                        <FlexGrid width="100%" alignItems="center" gap="0.5em">
                            {notificationIcons[option]}
                            <Text>{option}</Text>
                        </FlexGrid>
                    )}
                />
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                {/* <Input
                    label="Headline"
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    value={content.headline[activeLanguage]}
                    onChange={(val) =>
                        setInAppNotification((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                /> */}
                <TextEditor
                    label="Body Text"
                    value={content.bodyText[activeLanguage]}
                    onChange={(val) =>
                        setInAppNotification((state) => {
                            const newState = { ...state };
                            newState.content.bodyText[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <TargetLink
                    value={content.targetLink[activeLanguage]}
                    isLinkInternal={content.isLinkInternal}
                    title="Target Link"
                    onChange={(val) =>
                        setInAppNotification((state) => {
                            const newState = { ...state };
                            newState.content.isLinkInternal =
                                val.isLinkInternal;
                            newState.content.targetLink[activeLanguage] =
                                val.linkSource;
                            return newState;
                        })
                    }
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            if (
                                content.targetLink &&
                                content.targetLink[activeLanguage]
                            ) {
                                if (content.isLinkInternal) {
                                    if (
                                        !content.targetLink[
                                            activeLanguage
                                        ].match(INTERNAL_LINK_SOURCE_PATERN)
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content:
                                                    'Please start the URL with fastscan:// for linking an internal page',
                                            })
                                        );
                                        return;
                                    }
                                } else if (
                                    !content.targetLink[activeLanguage].match(
                                        EXTERNAL_LINK_SOURCE_PATERN
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content:
                                                'Please start the URL with https:// for a secure and valid connection ',
                                        })
                                    );
                                    return;
                                }
                            }

                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.bodyText[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Body Text in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
