import _ from 'lodash';

export const STORAGE_BOOKMARKED_ARTICLE_IDS = 'STORAGE_BOOKMARKED_ARTICLE_IDS';
export const STORAGE_LIKE_VIDEO_IDS = 'STORAGE_LIKE_VIDEO_IDS';
export const STORAGE_DISLIKE_VIDEO_IDS = 'STORAGE_DISLIKE_VIDEO_IDS';
export const STORAGE_SAVED_VIDEO_IDS = 'STORAGE_SAVED_VIDEO_IDS';
export const STORAGE_WATCH_LATER_VIDEO_IDS = 'STORAGE_WATCH_LATER_VIDEO_IDS';

export const STORAGE_ROLE_ID = 'fastscan-app-roleid';
export const STORAGE_ROLES = 'fastscan-app-roles';

export const STORAGE_RECENT_SEARCHED = 'STORAGE_RECENT_SEARCHED';
// import COUNTRY_LANGUAGE_MULTIPLE_MAP from '../assets/locales/country_default_language_mapping.json';

export const COUNTRY_LANGUAGE_MULTIPLE_MAP = {
    Thailand: ['Thai'],
    Germany: ['German'],
    Poland: ['Polish'],
    'Ireland/UK': ['English'],
    Spain: ['Spanish'],
    France: ['French'],
    Portugal: ['Portuguese'],
    'Czech Republic': ['Czech'],
    Slovakia: ['Slovak'],
    Hungary: ['Hungarian'],
    SingaporeMalaysia: ['Simplified Chinese', 'Malay'],
    SouthAfrica: ['English'],
    Brazil: ['Portuguese'],
};

// export const COUNTRY_LANGUAGE_MAP = _.assign(
//     {},
//     ..._.map(COUNTRY_LANGUAGE_MULTIPLE_MAP, (v, k) => ({ [k]: v[0] }))
// );
// console.log('contry language map', COUNTRY_LANGUAGE_MAP);

export const LANGUAGE_CONTENTFUL_MAP = {
    English: 'en-US',
    French: 'fr',

    Thai: 'th-TH',
    German: 'de',
    Polish: 'pl',
    Spanish: 'es',
    Portuguese: 'pt-PT',
    Czech: 'cs-CZ',
    Slovak: 'sk-SK',
    Hungarian: 'hu-HU',

    Malay: 'ms-MY',
    'Simplified Chinese': 'zh-Hans',
};

export const API_LANGUAGE_CODE_MAPPING = {
    English: 'en',
    French: 'fr',
    Thai: 'th',
    German: 'de',
    Polish: 'pl',
    Spanish: 'es',
    Portuguese: 'pr',
    Czech: 'cs',
    Slovak: 'sk',
    Hungarian: 'hu',

    Malay: 'ms',
    'Simplified Chinese': 'zh-Hans',
};

export const LANGUAGE_CONTENTFUL_MAP_REVERSE = _.assign(
    {},
    ..._.map(LANGUAGE_CONTENTFUL_MAP, (v, k) => ({ [v]: k }))
);

export const LANGUAGE = 'English';
export const DEFAULT_LANGUAGE_CODE = LANGUAGE_CONTENTFUL_MAP[LANGUAGE];

export const ROLE_TAG_ID_MAP: Record<string, string> = {
    '2gLWX854BMpAJXva0uF0Jp': 'france',
    '5yxs87hlgxSq0YPDrfaibl': 'germany',
    '3wmWNUz1a7KhmTUyP13hPx': 'poland',
    '03eJNAqd6Gj43wwf5YowVz': 'spain',
    '52HP3dxE0eo4JPyvwa1s35': 'thailand',
    '4xXTPsrmRlctOerkkpNbzC': 'uk',
    '1QroDVkKkftYTagAvPPEjx': 'portugal',
    '5TzpR9D26zqqlaQXiBrOA4': 'czech',
    '5TC1P4f0h8xWdQTI1GzdCI': 'slovakia',
    '3dIcJtX9pW0b6ie1eyWgeJ': 'hungary',
    '58rqT2F8EP5Cwfx7WMbrfI': 'singaporeMalaysia',
    '6nSLTFNCxxrmT85eZo15JL': 'southAfrica',
    '1CDA9cUbvG2MwALzOLenXI': 'brazil',
};

export const COUNTRY_TAG_MAP: Record<string, string> = {
    Thailand: 'thailand',
    Germany: 'germany',
    Poland: 'poland',
    'Ireland/UK': 'uk',
    Spain: 'spain',
    France: 'france',
    Portugal: 'portugal',
    'Czech Republic': 'czech',
    Slovakia: 'slovakia',
    Hungary: 'hungary',
    Ireland: 'ireland',

    SingaporeMalaysia: 'singaporeMalaysia',
    SouthAfrica: 'southAfrica',
    Brazil: 'brazil',
};

export const TAG_COUNTRY_MAP = _.assign(
    {},
    ..._.map(COUNTRY_TAG_MAP, (v, k) => ({ [v]: k }))
);

export const COUNTRY_SHORT_TAG_MAP: Record<string, string> = {
    Thailand: 'TH',
    Germany: 'DE',
    Poland: 'PL',
    'Ireland/UK': 'UK', // uses for pushnotifications
    UK: 'UK', // uses oil selector
    Spain: 'ES',
    France: 'FR',
    Portugal: 'PT',
    'Czech Republic': 'CS',
    Slovakia: 'SK',
    Hungary: 'HU',
    SingaporeMalaysia: 'SG-MY',
    SouthAfrica: 'ZA',
    'Republic of Ireland': 'IE',
    Brazil: 'BR',
};

export const SHORT_TAG_COUNTRY_MAP = _.assign(
    {},
    ..._.map(COUNTRY_SHORT_TAG_MAP, (v, k) => ({ [v]: k }))
);

// IANA timezone identifiers
export const COUNTRY_DEFAULT_TIMEZONE = {
    Thailand: 'Asia/Bangkok',
    Germany: 'Europe/Berlin',
    Poland: 'Europe/Warsaw',
    'Ireland/UK': 'Europe/London',
    Spain: 'Europe/Madrid',
    France: 'Europe/Paris',
    Portugal: 'Europe/Lisbon',
    'Czech Republic': 'Europe/Prague',
    Slovakia: 'Europe/Bratislava',
    Hungary: 'Europe/Budapest',
    SingaporeMalaysia: 'Asia/Singapore',
    SouthAfrica: 'Africa/Johannesburg',
    Brazil: 'America/Fortaleza',
};

export const CONTENTFUL_TO_ROLE_TYPE_MAPPING = {
    Workshop: 'Workshop Owner',
    Dealers: 'Outlet',
    Mechanics: 'Mechanic',
};

export const EXTERNAL_LINK_SOURCE_PATERN = new RegExp(
    '^(https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$'
);

export const INTERNAL_LINK_SOURCE_PATERN = new RegExp(
    '^(fastscan):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$'
);

export const USER_TYPE_OPTIONS = [
    { label: 'Mechanics', value: 'Mechanics' },
    { label: 'Dealers', value: 'Dealers' },
    { label: 'Workshop', value: 'Workshop' },
];
export const UK_OPTIONS = [
    { label: 'UK', value: 'uk' },
    { label: 'Republic of Ireland', value: 'ireland' },
];

export const INTERNAL_PAGE_LINK = {
    'Home Page': 'fastscan://dashboard',
    'Points Screen': 'fastscan://points',
    'Redemption Screen': 'fastscan://redemption',
    'Help Section': 'fastscan://help',
    'My Account Section': 'fastscan://profile',
    'My Account | Profile': 'fastscan://editProfile',
    'My Account | My Mechanics': 'fastscan://manageMechanics',
    'Media Section | Videos': 'fastscan://media/1',
    'Media Section | Articles': 'fastscan://media/2',
    'Learning Cards': 'fastscan://learningCardHomeScreen',
};

export const INTERNAL_PAGE_LINK_REVERSED = _.invert(INTERNAL_PAGE_LINK);

export const IMAGE_SIZE_MIN_WIDTH = 315;
export const IMAGE_SIZE_MIN_HEIGHT = 157;

export const LIMIT_NAME_LENGTH = 50;
export const LIMIT_UPLOAD_MOBILE = 20;
export const LIMIT_UPLOAD_PID = 20;
export const LIMIT_HEADLINE_LENGTH = 100;
export const LIMIT_DESCRIPTION_LENGTH = 400;
export const LIMIT_NOTIFICATION_LENGTH = 350;
export const LIMIT_DEFAULT_TEXT_LENGTH = 255;
export const PLACEHOLDER_IMAGE = '/assets/images/placeholder.png';
export const NUM_OPTIONS_QUIZ = 4;
export const MAX_POINTS = 9999;

export const MAX_NUM_CONTEST_SCHEMES = 5;
