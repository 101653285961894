import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const AccountMultipleUsers: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path
            className="icon-fill"
            data-name="Path 4769"
            d="M16 17v2H2v-2s0-4 7-4 7 4 7 4m-3.5-9.5A3.5 3.5 0 109 11a3.5 3.5 0 003.5-3.5m3.44 5.5A5.32 5.32 0 0118 17v2h4v-2s0-3.63-6.06-4M15 4a3.39 3.39 0 00-1.93.59 5 5 0 010 5.82A3.39 3.39 0 0015 11a3.5 3.5 0 000-7z"
            fill={color || '#009343'}
        />
    </svg>
);

export default AccountMultipleUsers;
