import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { CheckBoxGroup } from '../../../components/CheckBoxGroup';
import { FlexGrid } from '../../../components/FlexGrid';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Select } from '../../../components/Select';
import { Text } from '../../../components/Text';
import { TextEditor } from '../../../components/TextEditor';
import { COLORS } from '../../../styles/variables';
import { ArticleType } from '../../../types';
import { notificationIcons } from '../../Videos/components/SendNotification';
import { LIMIT_NOTIFICATION_LENGTH } from '../../../common/constants';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    articleState: [
        ArticleType,
        React.Dispatch<React.SetStateAction<ArticleType>>
    ];
}

export const SendNotication: React.FC<Props> = ({
    stepNavigator,
    articleState,
}) => {
    const [article, setArticle] = articleState;
    const { sendNotification } = article;

    const isInAppNotification = sendNotification.notificationType.includes(
        'In-App Notifications'
    );

    const isPushNotification =
        sendNotification.notificationType.includes('Push notifications');

    const { appLanguages } = article.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    // const dataIncomplete = !sendNotification.notificationType.length;

    useEffect(() => {
        const newArticle = { ...article };
        newArticle.identifierDefinition.appLanguages.forEach((language) => {
            newArticle.sendNotification.pushNotificationContent.headline[
                language
            ] = '';
            newArticle.sendNotification.pushNotificationContent.bodyText[
                language
            ] = '';
            newArticle.sendNotification.inAppNotificationContent.bodyText[
                language
            ] = '';
        });
        setArticle(newArticle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     sendNotification.headline[language] &&
            //         sendNotification.bodyText[language]
            // )
            language === activeLanguage
    );

    console.log('sendNotification', sendNotification);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Send Notification
                    </Text>
                }
            />
            <Form>
                <CheckBoxGroup
                    direction="column"
                    values={sendNotification.notificationType}
                    onChange={(val: string[]) =>
                        setArticle({
                            ...article,
                            sendNotification: {
                                ...article.sendNotification,
                                notificationType: val,
                            },
                        })
                    }
                    label="Send notification when new articles uploaded."
                    info={{
                        title: 'Send Notification',
                        content:
                            'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                    }}
                    options={[
                        {
                            label: 'In-App Notifications',
                            value: 'In-App Notifications',
                        },
                        {
                            label: 'Push notifications',
                            value: 'Push notifications',
                        },
                    ]}
                />
                <HorizontalLine
                    m="0em 0em 0.5em 0em"
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />
                {isInAppNotification && (
                    <FlexGrid direction="column" gap="1em" width="100%">
                        <Text uppercase font="OS" fontSize="1.5rem">
                            In-App Notification
                        </Text>
                        <Select
                            form
                            label="icon"
                            placeholder="- Select Icon -"
                            info={{
                                title: 'In-App Notification icon',
                                content:
                                    'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                            }}
                            options={[
                                'Alert',
                                'Comment',
                                'Reward',
                                'New Release',
                                'New Video',
                                'New Article',
                                'New Quiz',
                                'Points Allocation',
                                'Learning Card Release',
                            ]}
                            value={sendNotification.icon}
                            onChange={(val: string) =>
                                setArticle({
                                    ...article,
                                    sendNotification: {
                                        ...article.sendNotification,
                                        icon: val,
                                    },
                                })
                            }
                            renderValue={(value: string) => (
                                <FlexGrid
                                    width="100%"
                                    alignItems="center"
                                    gap="0.5em"
                                >
                                    {notificationIcons[value]}
                                    <Text>{value}</Text>
                                </FlexGrid>
                            )}
                            renderOption={(option: string) => (
                                <FlexGrid
                                    width="100%"
                                    alignItems="center"
                                    gap="0.5em"
                                >
                                    {notificationIcons[option]}
                                    <Text>{option}</Text>
                                </FlexGrid>
                            )}
                        />
                        <ButtonGroup
                            titles={appLanguages}
                            info={{
                                title: 'Multiple Languages',
                                content:
                                    'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                            }}
                            active={activeLanguage}
                            onChange={setActiveLanguage}
                            checked={checkedLanguage}
                        />
                        {/* <Input
                            info={{
                                title: 'Headline',
                                content: 'Short description',
                            }}
                            label="Headline"
                            value={sendNotification.headline[activeLanguage]}
                            onChange={(val) =>
                                setArticle((state) => {
                                    const newState = { ...state };
                                    newState.sendNotification.headline[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                })
                            }
                        /> */}
                        <TextEditor
                            label="Body Text"
                            value={
                                sendNotification.inAppNotificationContent
                                    .bodyText[activeLanguage]
                            }
                            onChange={(val) => {
                                setArticle((state) => {
                                    const newState = { ...state };
                                    newState.sendNotification.inAppNotificationContent.bodyText[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                });
                            }}
                        />
                        {/* <Select
                            form
                            placeholder="Select"
                            label="Target Link"
                            options={['Acme target link']}
                            value={sendNotification.targetLink}
                            onChange={(val: string) =>
                                setArticle({
                                    ...article,
                                    sendNotification: {
                                        ...article.sendNotification,
                                        targetLink: val,
                                    },
                                })
                            }
                        /> */}
                    </FlexGrid>
                )}
                {isPushNotification && (
                    <FlexGrid direction="column" gap="1em" width="100%">
                        <Text uppercase font="OS" fontSize="1.5rem">
                            Push Notification
                        </Text>
                        <ButtonGroup
                            titles={appLanguages}
                            info={{
                                title: 'Multiple Languages',
                                content:
                                    'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                            }}
                            active={activeLanguage}
                            onChange={setActiveLanguage}
                            checked={checkedLanguage}
                        />
                        <Input
                            info={{
                                title: 'Preview Text',
                                content: 'Short description',
                            }}
                            label="Preview Text"
                            value={
                                sendNotification.pushNotificationContent
                                    .headline[activeLanguage]
                            }
                            onChange={(val) =>
                                setArticle((state) => {
                                    const newState = { ...state };
                                    newState.sendNotification.pushNotificationContent.headline[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                })
                            }
                        />
                        <Input
                            multiline
                            height="200px"
                            placeholder="Enter"
                            label="Notification Text"
                            maxLength={LIMIT_NOTIFICATION_LENGTH}
                            value={
                                sendNotification.pushNotificationContent
                                    .bodyText[activeLanguage]
                            }
                            onChange={(val) => {
                                setArticle((state) => {
                                    const newState = { ...state };
                                    newState.sendNotification.pushNotificationContent.bodyText[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                });
                            }}
                        />
                        {/* <Select
                            form
                            placeholder="Select"
                            label="Target Link"
                            options={['Acme target link']}
                            value={sendNotification.targetLink}
                            onChange={(val: string) =>
                                setArticle({
                                    ...article,
                                    sendNotification: {
                                        ...article.sendNotification,
                                        targetLink: val,
                                    },
                                })
                            }
                        /> */}
                    </FlexGrid>
                )}
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        // disabled={dataIncomplete}
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
