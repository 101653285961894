import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewVideo: React.FC<Props> = ({ width, height, color }) => (
    <svg
        width={width || 30}
        height={height || 30}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="21"
            cy="21"
            r="21"
            fill={color || '#009343'}
            className="icon-fill"
        />
        <path
            className="icon-rest"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.369 10H20.5586L24.7053 15.9649H20.5581L16.369 10ZM32 15.9649H28.3641L24.175 10H27.4229C29.9508 10 32 12.0492 32 14.5771V15.9649ZM12.9575 10.2948C11.229 10.9489 10 12.6195 10 14.5771V15.9649L16.8994 15.9649L12.9575 10.2948ZM10 17.9201L32 17.9201V27.4229C32 29.9508 29.9508 32 27.4229 32H14.5771C12.0492 32 10 29.9508 10 27.4229V17.9201ZM24.8416 25.0262C25.2993 24.7619 25.2993 24.1013 24.8416 23.837L19.1849 20.5711C18.7272 20.3068 18.155 20.6372 18.155 21.1657V27.6975C18.155 28.226 18.7272 28.5563 19.1849 28.2921L24.8416 25.0262Z"
            fill={color || 'white'}
        />
    </svg>
);

export default SvgNewVideo;
