import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgPointsallocation: React.FC<Props> = ({ width, height, color }) => (
    <svg
        width={width || 30}
        height={height || 30}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="21"
            cy="21"
            r="21"
            className="icon-fill"
            fill={color || '#CCA403'}
        />
        <path
            d="M16.5 9L19.4007 15.5075L26.4861 16.2553L21.1935 21.025L22.6717 27.9947L16.5 24.435L10.3283 27.9947L11.8065 21.025L6.51391 16.2553L13.5993 15.5075L16.5 9Z"
            fill="white"
            className="icon-rest"
        />
        <path
            d="M30 20L31.6576 23.7186L35.7063 24.1459L32.682 26.8714L33.5267 30.8541L30 28.82L26.4733 30.8541L27.318 26.8714L24.2937 24.1459L28.3424 23.7186L30 20Z"
            fill="white"
            className="icon-rest"
        />
    </svg>
);

export default SvgPointsallocation;
