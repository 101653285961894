import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgLearningCardRelease: React.FC<Props> = ({ width, height, color }) => (
    <svg
        width={width || 30}
        height={height || 30}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="21"
            cy="21"
            r="21"
            fill={color || '#0A91A5'}
            className="icon-fill"
        />
        <path
            d="M17.4765 27.9876C16.3763 27.8895 15.5639 26.9182 15.6619 25.818L17.0821 9.88139C17.1801 8.78118 18.1515 7.96876 19.2517 8.06681L29.212 8.95441C30.3122 9.05246 31.1247 10.0238 31.0266 11.124L29.6064 27.0606C29.5084 28.1608 28.537 28.9732 27.4368 28.8752L17.4765 27.9876Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0695 25.6759L15.4897 9.73937C15.6662 7.75899 17.4147 6.29664 19.3951 6.47312L29.3554 7.36072C31.3358 7.5372 32.7981 9.28568 32.6216 11.2661L31.2015 27.2026C31.025 29.183 29.2765 30.6453 27.2961 30.4689L17.3358 29.5813C15.3554 29.4048 13.8931 27.6563 14.0695 25.6759ZM15.6632 25.8179C15.5652 26.9182 16.3776 27.8895 17.4778 27.9876L27.4381 28.8752C28.5384 28.9732 29.5097 28.1608 29.6078 27.0606L31.0279 11.124C31.126 10.0238 30.3136 9.05245 29.2134 8.95441L19.253 8.0668C18.1528 7.96876 17.1814 8.78118 17.0834 9.88139L15.6632 25.8179Z"
            fill="#0A91A5"
        />
        <path
            d="M21.8847 12.0763C22.9526 11.7942 24.0471 12.4312 24.3293 13.4991L28.4163 28.968C28.6985 30.0359 28.0615 31.1304 26.9936 31.4125L16.3587 34.2224C15.2908 34.5045 14.1964 33.8676 13.9142 32.7996L9.82711 17.3307C9.54495 16.2628 10.1819 15.1684 11.2499 14.8862L21.8847 12.0763Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8766 13.0904L29.9637 28.5593C30.4716 30.4816 29.325 32.4516 27.4028 32.9595L16.7679 35.7694C14.8457 36.2773 12.8756 35.1307 12.3678 33.2084L8.28067 17.7395C7.77278 15.8173 8.91936 13.8472 10.8416 13.3393L21.4765 10.5295C23.3987 10.0216 25.3688 11.1682 25.8766 13.0904ZM24.3297 13.4991C24.0476 12.4312 22.9531 11.7942 21.8852 12.0764L11.2503 14.8863C10.1824 15.1684 9.54543 16.2629 9.82759 17.3308L13.9147 32.7997C14.1968 33.8676 15.2913 34.5046 16.3592 34.2224L26.9941 31.4126C28.062 31.1304 28.699 30.036 28.4168 28.968L24.3297 13.4991Z"
            fill="#0A91A5"
        />
        <path
            d="M19.5553 19.0784C19.7032 20.8384 18.3963 22.3851 16.6364 22.533C14.8764 22.6808 13.3297 21.374 13.1818 19.614C13.0339 17.854 14.3408 16.3074 16.1008 16.1595C17.8608 16.0116 19.4074 17.3184 19.5553 19.0784Z"
            fill="#0A91A5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.8961 23.8665C24.0156 24.3183 23.7461 24.7815 23.2943 24.9009L16.233 26.7681C15.7811 26.8876 15.318 26.6182 15.1985 26.1663C15.0791 25.7145 15.3485 25.2514 15.8003 25.1319L22.8617 23.2647C23.3135 23.1453 23.7766 23.4147 23.8961 23.8665Z"
            fill="#0A91A5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.7984 27.2303C24.9179 27.6821 24.6485 28.1452 24.1967 28.2647L17.1353 30.1319C16.6835 30.2513 16.2203 29.9819 16.1009 29.5301C15.9814 29.0783 16.2508 28.6151 16.7027 28.4957L23.764 26.6285C24.2158 26.509 24.679 26.7785 24.7984 27.2303Z"
            fill="#0A91A5"
        />
    </svg>
);

export default SvgLearningCardRelease;
