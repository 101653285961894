import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewQuiz: React.FC<Props> = ({ width, height, color }) => (
    <svg
        width={width || 30}
        height={height || 30}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="21"
            cy="21"
            r="21"
            fill={color || '#009343'}
            className="icon-fill"
        />
        <ellipse
            cx="22.3205"
            cy="17.3077"
            rx="10.6779"
            ry="8.30769"
            fill="white"
            className="icon-rest"
        />
        <path
            d="M30.2128 21.4614C30.2128 23.4008 29.5307 25.0231 28.3558 26.5383L30.2128 32.0767L24.5 30.5C22.6908 31.4727 20.3213 32 18 32C11.59 32 7 26.8143 7 21.4614C7 16.1084 12.1964 11.769 18.6064 11.769C25.0165 11.769 30.2128 16.1084 30.2128 21.4614Z"
            fill="white"
            className="icon-rest"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6056 12.5692C12.4963 12.5692 7.79922 16.6794 7.79922 21.4615C7.79922 26.3791 12.0375 31.2002 17.9992 31.2002C20.2135 31.2002 22.448 30.6947 24.1204 29.7956L24.4029 29.6437L28.9756 30.9058L27.4604 26.3867L27.7228 26.0483C28.8125 24.643 29.4121 23.1886 29.4121 21.4615C29.4121 16.6794 24.7149 12.5692 18.6056 12.5692ZM6.19922 21.4615C6.19922 15.5378 11.8948 10.9692 18.6056 10.9692C25.3164 10.9692 31.0121 15.5378 31.0121 21.4615C31.0121 23.4614 30.3516 25.1488 29.2468 26.6817L31.4485 33.2481L24.5886 31.3548C22.6873 32.3035 20.3087 32.8002 17.9992 32.8002C11.1409 32.8002 6.19922 27.2498 6.19922 21.4615Z"
            fill="#009343"
            className="icon-fill"
        />
        <path
            d="M20.041 25.5796H17.8633C17.8698 24.9741 17.9219 24.4663 18.0195 24.0562C18.1172 23.6395 18.2799 23.2619 18.5078 22.9233C18.7422 22.5848 19.0514 22.2267 19.4355 21.8491C19.735 21.5627 20.0052 21.2925 20.2461 21.0386C20.487 20.7782 20.679 20.5047 20.8223 20.2183C20.9655 19.9253 21.0371 19.59 21.0371 19.2124C21.0371 18.8022 20.9688 18.4539 20.832 18.1675C20.6953 17.881 20.4935 17.6629 20.2266 17.5132C19.9661 17.3634 19.6406 17.2886 19.25 17.2886C18.9245 17.2886 18.6185 17.3537 18.332 17.4839C18.0456 17.6076 17.8145 17.8029 17.6387 18.0698C17.4629 18.3302 17.3685 18.6753 17.3555 19.105H15.002C15.015 18.2847 15.2103 17.5946 15.5879 17.0347C15.9655 16.4748 16.4733 16.0549 17.1113 15.7749C17.7493 15.495 18.4622 15.355 19.25 15.355C20.1224 15.355 20.8678 15.5047 21.4863 15.8042C22.1048 16.0972 22.5768 16.5269 22.9023 17.0933C23.2344 17.6532 23.4004 18.3302 23.4004 19.1245C23.4004 19.6974 23.2865 20.2183 23.0586 20.687C22.8307 21.1493 22.5345 21.5822 22.1699 21.9858C21.8053 22.383 21.4115 22.7801 20.9883 23.1772C20.6237 23.5093 20.3763 23.8706 20.2461 24.2612C20.1159 24.6453 20.0475 25.0848 20.041 25.5796ZM17.668 28.6265C17.668 28.2749 17.7884 27.9787 18.0293 27.7378C18.2702 27.4904 18.599 27.3667 19.0156 27.3667C19.4323 27.3667 19.7611 27.4904 20.002 27.7378C20.2428 27.9787 20.3633 28.2749 20.3633 28.6265C20.3633 28.978 20.2428 29.2775 20.002 29.5249C19.7611 29.7658 19.4323 29.8862 19.0156 29.8862C18.599 29.8862 18.2702 29.7658 18.0293 29.5249C17.7884 29.2775 17.668 28.978 17.668 28.6265Z"
            fill="#009343"
            className="icon-fill"
        />
    </svg>
);

export default SvgNewQuiz;
