import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewArticle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        width={width || 30}
        height={height || 30}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="21"
            cy="21"
            r="21"
            fill={color || '#009343'}
            className="icon-fill"
        />
        <path
            d="M28.8848 13.9722H29.8073C31.0177 13.9722 31.999 14.9535 31.999 16.1639V29.0169C31.999 29.8769 31.3019 30.574 30.4419 30.574V30.574C29.5819 30.574 28.8848 29.8769 28.8848 29.0169V13.9722Z"
            fill="white"
            className="icon-rest"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6301 10C11.1776 10 10 11.1776 10 12.6301V29.3699C10 30.8224 11.1776 32 12.6301 32H25.4709H28.987L28.3483 31.6191C27.6853 31.2237 27.2792 30.5086 27.2792 29.7367V12.6301C27.2792 11.1776 26.1016 10 24.649 10H12.6301ZM21.0006 13.8263H13.0293V20.5219H21.0006V13.8263ZM12.7129 22.3637H24.8686V23.9934H12.7129V22.3637ZM13.8088 25.968C13.2035 25.968 12.7129 26.4587 12.7129 27.0639V27.5976H24.0538C24.5038 27.5976 24.8686 27.2328 24.8686 26.7828C24.8686 26.3328 24.5038 25.968 24.0538 25.968H13.8088Z"
            fill="white"
            className="icon-rest"
        />
    </svg>
);

export default SvgNewArticle;
